import "./AboutSection.scss";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <div className="about">
      <div className="scrolling-text">
        <div id="scroll-container">
          <div id="scroll-text-first">
            FRONT-END ENGINEER FRONT-END ENGINEER FRONT-END ENGINEER
          </div>
        </div>
        <div id="scroll-container">
          <div id="scroll-text-second">
            REACT DEVELOPER REACT DEVELOPER REACT DEVELOPER
          </div>
        </div>
      </div>
      <div className="about-me">
        {/* <p>Hi, my name is</p> */}
        <span className="name">Hello I'm Zana.</span>{" "}
        <span> React Developer &amp; Trainer</span>
        <Link to="/about">
          <button className="more-about-me">Resumé</button>
        </Link>
      </div>
      {/* <h3>Currently working at cedX</h3> */}
      {/* <Link to="/about" onClick={() => window.scroll(0, 0)}>
            <div class="arrow">
              <div class="arrow-top"></div>
              <div class="arrow-bottom"></div>
            </div>
          </Link> */}
    </div>
  );
};

export default AboutSection;
