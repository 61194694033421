import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Cursor } from "../Cursor/Cursor";

const Layout = ({ children }) => {
  return (
    <>
      <Cursor />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh'}}>
        <Navbar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
