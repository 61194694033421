import { useState } from "react";
import "./Navbar.scss";
import { Rotate as Hamburger } from "hamburger-react";
import Drawer from "../Drawer/Drawer";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false)

  const closeModal = ()=>(
    setOpen(false)
  )
  return (
    <div className="nav">
      <div
        className="hamburger-menu"
      >
        <Hamburger toggled={isOpen} toggle={setOpen} direction="right" color="#DFD3C3" className="hamburger-menu-logo"/>
      </div>
      {isOpen ? <Drawer handleCloseModal={closeModal}/> : null}
    </div>
  );
};

export default Navbar;
