import "./Footer.scss";
import { RiLinkedinLine } from "react-icons/ri";
import { RiGithubLine } from "react-icons/ri";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-text">© Zana Shala – {new Date().getFullYear()}</div>
        <div className="social-media">
          <i>
            <a href="https://www.linkedin.com/in/zanashala/">
              <RiLinkedinLine className="sm-icon" />
            </a>
          </i> 
          <i>
            <a href="https://github.com/ZShala">
              <RiGithubLine className="sm-icon" />
            </a>
          </i>
        </div>
      </div>
    </>
  );
};

export default Footer;
