import "./AboutPage.scss";
import { Link } from "react-router-dom";
import JavaScriptLogo from "../../assets/img/javascript-logo.png";
import ReactLogo from "../../assets/img/react.png";
import TypeScriptLogo from "../../assets/img/typescript.png";
import NextJsLogo from "../../assets/img/next-js-logo.png";
import ReduxLogo from "../../assets/img/redux-logo.png";
import FirebaseLogo from "../../assets/img/firebase-logo.webp";
import GithubLogo from "../../assets/img/github-logo.png";
import SassLogo from "../../assets/img/sass-logo.png";
import StyledComponentsLogo from "../../assets/img/styled-components-logo.png";
import ChakraLogo from "../../assets/img/chakra-logo.png";
import FigmaLogo from "../../assets/img/figma-logo.png";
import TailwindLogo from "../../assets/img/tailwind-css-logo.webp";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

const AboutPage = () => {
  const ref = useRef();
  const { events } = useDraggable(ref);
  return (
    <>
    <div className="container">
      <div className="experience">
        <div className="experience-title">PROFESSIONAL CAREER</div>
        <div className="experience-content" {...events} ref={ref}>
          <div className="experience-section">
            <h1>CrewNew, React Developer</h1>
            <h4>July 2021 - Present | Remote</h4>
            <ul className="experience-list">
              <li>Building stable and maintainable codebases using React.</li>
              <li>
                Implementing web design and development principles to build
                stable software.
              </li>
              <li>
                Collaborating closely with the team to support projects during
                all phases of delivery.
              </li>
            </ul>
          </div>
          <div className="experience-section">
            <h1>Eduwo, React Developer, Internship</h1>
            <h4>April 2021 - July 2021 | Remote</h4>
            <ul className="experience-list">
              <li>Worked in a education platform based in Switzerland.</li>
            </ul>
          </div>
          <div className="experience-section">
            <h1>
              Primary School "Lidhja e Prizrenit", Mathematics and Technology
              Teacher
            </h1>
            <h4>February 2021 - July 2021 | Peja</h4>
            <ul className="experience-list">
              <li>
                Taught a variety of math and technology courses to 8th-9th
                grades including integrated and traditional curriculums.
              </li>
              <li>
                Integrated technology and computers and researching skills to
                build curiosity and learning.
              </li>
            </ul>
          </div>
          <div className="experience-section">
            <h1>cedX, Trainer</h1>
            <h4>February 2019 - Present | Peja</h4>
            <ul className="experience-list">
              <li>
                Trained students on React module (including React Hooks, Redux,
                React Animations, etc.).
              </li>
              <li>
                Trained students on Web Development module, instructed and
                coordinated effectively a team of students on developing and
                implementing their website.
              </li>
            </ul>
            <h4>September 2018 - May 2019 | Peja</h4>
            <ul className="experience-list">
              <li>
                Trained students on Web and Mobile Game Development (design,
                testing, etc.).
              </li>
              <li>
                Trained students on STEAM class, Technology and Mathematics
                modules.
              </li>
            </ul>
          </div>
          <div className="experience-section">
            <h1>cedX, Front-end Developer</h1>
            <h4>May 2019 - September 2019 | Peja</h4>
            <ul className="experience-list">
              <li>
                Demonstrated proficiency with HTML5, CSS3, pure JavaScript,
                React.
              </li>
              <li>Worked on several Web-based projects.</li>
              <li>Worked with version-control systems such as Git/GitHub.</li>
            </ul>
          </div>
          <div className="experience-section">
            <h1>jCoders Academy, Internship</h1>
            <h4>May 2017 - February 2018 | Prishtina</h4>
            <ul className="experience-list">
              <li>
                Teaching Assistant in modules: Game Development and
                WebDevelopment.
              </li>
              <li>
                Coached on several robotic projects, including arduino projects.
              </li>
            </ul>
          </div>
          <div className="experience-section">
            <h1>Peja Technology Workshop, Volunteer Work</h1>
            <h4>July 2017 | Peja</h4>
            <ul className="experience-list">
              <li>Trained volunteer on Web and Mobile Game Development.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="skills-tools">
        <div className="skills-tools-title">TECHNICAL SKILLS &amp; TOOLS</div>
        <div className="skills-tools-content">
       
          <div className="skills-tools-grid">
            <div>
              <img src={JavaScriptLogo} alt="" />
            </div>
            <div>
              <img src={ReactLogo} alt="" />
            </div>
            <div>
              <img src={TypeScriptLogo} alt="" />
            </div>
            <div>
              <img src={FirebaseLogo} alt="" />
            </div>
            <div>
              <img src={NextJsLogo} alt="" />
            </div>
            <div>
              <img src={ReduxLogo} alt="" />
            </div>

            <div>
              <img src={GithubLogo} alt="" />
            </div>
            <div>
              <img src={TailwindLogo} alt="" />
            </div>
            <div>
              <img src={ChakraLogo} alt="" />
            </div>
            <div>
              <img src={StyledComponentsLogo} alt="" />
            </div>
            <div>
              <img src={SassLogo} alt="" />
            </div>
            <div>
              <img src={FigmaLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="education">
        <div className="education-title">ACADEMIC BACKGROUND</div>
        <div className="education-content">
          <div className="education-section">
            <h1>
              University of Prishtina, Faculty of Mathematics and Natural
              Sciences
            </h1>
            <h4>Computer Science | October 2015 - February 2020</h4>
            <ul className="education-list">
              <li>Worked on several projects based on Java.</li>
              <li>
                Developed a full stack web app product in a team as a faculty
                project (pure JavaScript, PHP, MySQL).
              </li>
              <li>
                Bachelor Thesis “Potential of Augmented Reality in Business
                Development - Application in Fitness”.
              </li>
            </ul>
          </div>
          <div className="education-section">
            <h1>University for Business and Technology - UBT</h1>
            <h4>
              Master of Computer Science and Engineering | November 2021 -
              Present
            </h4>
          </div>
        </div>
      </div>
      <div className="accomplishments">
        <div className="accomplishments-title">ACCOMPLISHMENTS</div>
        <div className="accomplishments-content">
          <ul className="accomplishments-list">
            <li>The Complete Web Developer in 2020: Zero to Mastery, Udemy.</li>
            <li>
              The Complete Guide (incl Hooks, React Router, Redux), Udemy.
            </li>
            <li>
              Complete React Developer in 2020 (w/ Redux, Hooks, GraphQL),
              Udemy.
            </li>
            <li>Math is Everywhere: Applications of Finite Math, Udemy.</li>
            <li>
              Front-End Web UI Frameworks and Tools: Bootstrap 4, Coursera.
            </li>
            <li>Understanding TypeScript - 2021 Edition, Udemy.</li>
            <li>Advanced JavaScript.</li>
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutPage;
