import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import Layout from "./components/Layout/Layout";
import ContactPage from "./pages/ContactPage/ContactPage";
import MekkahOrientPage from "./pages/MekkahOrientPage/MekkahOrientPage";
import AmazonPage from "./pages/AmazonPage/AmazonPage";
import AirbnbPage from "./pages/AirbnbPage/AirbnbPage";
import ARPage from "./pages/ARPage/ARPage";
import LogisticNavPage from "./pages/LogisticNavPage/LogisticNavPage";
import ShergomePage from "./pages/ShergomePage/ShergomePage";
import FaceRecognitionPage from "./pages/FaceRecognitionPage/FaceRecognitionPage";
import LikeALocalPage from "./pages/LikeALocalPage/LikeALocalPage";

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact-me" element={<ContactPage/>} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/mekkah-orient" element={<MekkahOrientPage />} />
        <Route path="/projects/amazon" element={<AmazonPage />} />
        <Route path="/projects/airbnb" element={<AirbnbPage />} />
        <Route path="/projects/ar" element={<ARPage />} />
        <Route path="/projects/like-a-local" element={<LikeALocalPage />} />
        <Route path="/projects/logistic-nav" element={<LogisticNavPage />} />
        <Route path="/projects/shergome" element={<ShergomePage />} />
        <Route path="/projects/face-recognition" element={<FaceRecognitionPage />} />
      </Routes>
    </Layout>
  );
};

export default App;
