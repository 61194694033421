import "./ARPage.scss";

const ARPage = () => {
  return (
    <div className="container-ar">
      <div className="main-text-ar">Augmented Reality in Fitness</div>
      <h1 className="subtext-ar">
        TensorFlow, Posenet, ml5.js &amp; p5.js
      </h1>
      <div className="main-img-ar"></div>
      <div className="main-text-section-ar">
        <div>
          <h3>CLIENT</h3>
          <p>Personal Project</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Front End Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>3 months</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2020</p>
        </div>
      </div>

      <div className="images-ar">
        <div className="first-img-ar"></div>
        <div className="second-img-ar"></div>
        <div className="third-img-ar"></div>
        <div className="fourth-img-ar"></div>
      </div>
    </div>
  );
};

export default ARPage;
