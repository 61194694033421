import "./MekkahOrientPage.scss";

const MekkahOrientPage = () => {
  return (
    <div className="container-mekkah">
      <div className="main-text-mekkah">Mekkah Orient</div>
      <iframe
        className="main-img-mekkah"
        src="https://mekkahorient.com/"
        width="1300"
        height="450"
      ></iframe>
      <div className="main-text-section-mekkah">
        <div>
          <h3>CLIENT</h3>
          <p>Mekkah Orient</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Full Stack Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>3 months</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2023</p>
        </div>
      </div>
    </div>
  );
};

export default MekkahOrientPage;
