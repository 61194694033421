import "./ContactPage.scss";
import { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";

const initialInputs = {
  from_name: "",
  from_email: "",
  message: "",
};

export default function ContactPage() {
  const [inputs, setInputs] = useState(initialInputs);

  const form = useRef();

  const sendEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();

    console.log(form.current);

    emailjs
      .sendForm(
        "service_f9he288",
        "template_0e9038m",
        form.current,
        "odTuOcd2Pwf9x2wX7"
      )
      .then(
        (result) => {
          console.log(result.text);
          setInputs(initialInputs);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <form action="" className="contact-form" ref={form} onSubmit={sendEmail}>
        <div className="group">
          <label
            className={`${
              inputs.from_name.length ? "shrink" : ""
            } form-input-label`}
          >
            Your name:
          </label>
          <input
            type="text"
            className="form-input"
            name="from_name"
            value={inputs.from_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="group">
          <label
            className={`${
              inputs.from_email.length ? "shrink" : ""
            } form-input-label`}
          >
            Your email:
          </label>
          <input
            type="text"
            className="form-input"
            name="from_email"
            value={inputs.from_email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="group">
          <label
            className={`${
              inputs.message.length ? "shrink" : ""
            } form-input-label`}
          >
            About your project:
          </label>
          <input
            type="text"
            className="form-input"
            name="message"
            value={inputs.message}
            onChange={handleChange}
            required
          />
        </div>
        <button className="custom-button">Send</button>
      </form>
    </div>
  );
}
