import "./LogisticNavPage.scss";

const LogisticNavPage = () => {
  return (
    <div className="container-logistic-nav">
      <div className="main-text-logistic-nav">Logistic Nav</div>
      {/* <h1 className="subtext-logistic-nav">
        ReactJS, Webhooks, Stripe Checkout, Firestore, NextAuth
      </h1> */}
      <div className="main-img-logistic-nav"></div>
      <div className="main-text-section-logistic-nav">
        <div>
          <h3>CLIENT</h3>
          <p>Crewnew</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Front End Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>3 months</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2022</p>
        </div>
      </div>

      <div className="images-logistic-nav">
        <div className="first-img-logistic-nav"></div>
        <div className="second-img-logistic-nav"></div>
        <div className="third-img-logistic-nav"></div>
        <div className="fourth-img-logistic-nav"></div>
      </div>
    </div>
  );
};

export default LogisticNavPage;
