import "./AirbnbPage.scss";

const AirbnbPage = () => {
  return (
    <div className="container-airbnb">
      <div className="main-text-airbnb">Airbnb 2.0 with NEXT.JS</div>
      <h1 className="subtext-airbnb">
        ReactJS, Tailwind CSS, Mapbox, Calendar &amp; Date Picker
      </h1>
      <div className="main-img-airbnb"></div>
      <div className="main-text-section-airbnb">
        <div>
          <h3>CLIENT</h3>
          <p>Personal Project</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Front End Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>1 week</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2023</p>
        </div>
      </div>

      <div className="images-airbnb">
        <div className="first-img-airbnb"></div>
        <div className="second-img-airbnb"></div>
        <div className="third-img-airbnb"></div>
        <div className="fourth-img-airbnb"></div>
      </div>
    </div>
  );
};

export default AirbnbPage;
