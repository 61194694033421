import "./Drawer.scss";
import { Link } from "react-router-dom";

const Drawer = (props) => {
  return (
    <div className="drawer">
      <div className="container">
        <ul className="drawer-items">
          <li onClick={props.handleCloseModal}>
            <Link className="drawer-item" to="/" onClick={()=>window.scroll(0, 0)}>
              Home{" "}
            </Link>
          </li>
          <li onClick={props.handleCloseModal}>
            {" "}
            <Link className="drawer-item" to="/about" onClick={()=>window.scroll(0, 0)}>
              About{" "}
            </Link>
          </li>
          <li onClick={props.handleCloseModal}>
            <Link className="drawer-item" to="/projects" onClick={()=>window.scroll(0, 0)}>
              Work{" "}
            </Link>
          </li>
          <li onClick={props.handleCloseModal}>
            <Link className="drawer-item" to="/contact-me" onClick={()=>window.scroll(0, 0)}>
              Contact Me{" "}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
