import "./ShergomePage.scss";

const ShergomePage = () => {
  return (
    <div className="container-shergome">
      <div className="main-text-shergome">Shergome</div>
      {/* <h1 className="subtext-shergome">
        ReactJS, Webhooks, Stripe Checkout, Firestore, NextAuth
      </h1> */}
      <div className="main-img-shergome"></div>
      <div className="main-text-section-shergome">
        <div>
          <h3>CLIENT</h3>
          <p>Crewnew</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Front End Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>3 months</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2021</p>
        </div>
      </div>
    </div>
  );
};

export default ShergomePage;
