import "./LikeALocalPage.scss";

const LikeALocalPage = () => {
  return (
    <div className="container-like-a-local">
      <div className="main-text-like-a-local">Like a Local</div>
      {/* <h1 className="subtext-like-a-local">
        ReactJS, Webhooks, Stripe Checkout, Firestore, NextAuth
      </h1> */}
      <div className="main-img-like-a-local"></div>
      <div className="main-text-section-like-a-local">
        <div>
          <h3>CLIENT</h3>
          <p>Crewnew</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Front End Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>3 months</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2021</p>
        </div>
      </div>

      <div className="images-like-a-local">
        <div className="first-img-like-a-local"></div>
        <div className="second-img-like-a-local"></div>
        <div className="third-img-like-a-local"></div>
      </div>
    </div>
  );
};

export default LikeALocalPage;
