import "./FaceRecognitionPage.scss";

const FaceRecognitionPage = () => {
  return (
    <div className="container-face-recognition">
      <div className="main-text-face-recognition">Face Recognition App</div>
      <h1 className="subtext-face-recognition">
        ReactJS, NodeJS &amp; ExpressJS
      </h1>
      <div className="main-img-face-recognition"></div>
      <div className="main-text-section-face-recognition">
        <div>
          <h3>CLIENT</h3>
          <p>Personal Project</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Full Stack Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>3 months</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2023</p>
        </div>
      </div>
    </div>
  );
};

export default FaceRecognitionPage;
