import "./ProjectsPage.scss";
import { Link } from "react-router-dom";
import Carousel from "../../components/Carousel/Carousel";

const ProjectsPage = () => {
  return (
    <>
      <div className="projects-text" id="mekkah-orient">
        <ul>
          <li>
            <h1>Mekkah Orient</h1>
            <h4>Client Project</h4>
          </li>
          <li></li>
          <li>
            <Link
              to="/projects/mekkah-orient"
              onClick={() => window.scroll(0, 0)}
            >
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="mekkah-orient-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="logistic-nav">
        <ul>
          <li>
            <h1>Logistic Nav</h1>
            <h4>Client Project</h4>
          </li>
          <li></li>
          <li>
            <Link
              to="/projects/logistic-nav"
              onClick={() => window.scroll(0, 0)}
            >
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="logistic-nav-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="like-a-local">
        <ul>
          <li>
            <h1>Like a Local</h1>
            <h4>Client Project</h4>
          </li>
          <li></li>
          <li>
            <Link
              to="/projects/like-a-local"
              onClick={() => window.scroll(0, 0)}
            >
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="likealocal-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="shergome">
        <ul>
          <li>
            <h1>Shergome</h1>
            <h4>Client Project</h4>
          </li>
          <li></li>
          <li>
            <Link to="/projects/shergome" onClick={() => window.scroll(0, 0)}>
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="shergome-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="ar">
        <ul>
          <li>
            <h1>Augmented Reality in Fitness</h1>
            <h4>Personal Project</h4>
          </li>
          <li></li>
          <li>
            <Link to="/projects/ar" onClick={() => window.scroll(0, 0)}>
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="ar-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="airbnb">
        <ul>
          <li>
            <h1>Airbnb 2.0 with NEXT.JS</h1>
            <h4>Personal Project</h4>
          </li>
          <li>
            <Link to="/projects/airbnb" onClick={() => window.scroll(0, 0)}>
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="airbnb-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="amazon">
        <ul>
          <li>
            <h1>Amazon 2.0 with NEXT.JS</h1>
            <h4>Personal Project</h4>
          </li>
          <li>
            <Link to="/projects/amazon" onClick={() => window.scroll(0, 0)}>
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="amazon-img">
        <div className="layer"></div>
      </div>
      <div className="projects-text" id="face-recognition">
        <ul>
          <li>
            <h1>Face Recognition App</h1>
            <h4>Personal Project</h4>
          </li>
          <li>
            <Link
              to="/projects/face-recognition"
              onClick={() => window.scroll(0, 0)}
            >
              <button className="view-more-btn">View More</button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="face-recognition-img">
        <div className="layer"></div>
      </div>
      {/* <Carousel /> */}
    </>
  );
};

export default ProjectsPage;
