import Navbar from "../../components/Navbar/Navbar";
import AboutSection from "../../components/AboutSection/AboutSection";
import Skills from "../../components/Skills/Skills";
import Projects from "../../components/Projects/Projects";
import Carousel from "../../components/Carousel/Carousel";

const HomePage = () => {
  return (
    <> 
      <AboutSection />
      {/* <Skills />*/}
      {/* <Projects /> */}
      {/* <Carousel />  */}
    </>
  );
};

export default HomePage;