import "./AmazonPage.scss";

const AmazonPage = () => {
  return (
    <div className="container-amazon">
      <div className="main-text-amazon">Amazon 2.0 with NEXT.JS</div>
      <h1 className="subtext-amazon">
        ReactJS, Webhooks, Stripe Checkout, Firestore, NextAuth
      </h1>
      <div className="main-img-amazon"></div>
      <div className="main-text-section-amazon">
        <div>
          <h3>CLIENT</h3>
          <p>Personal Project</p>
        </div>
        <div>
          <h3>CATEGORY</h3>
          <p>Front End Developer</p>
        </div>
        <div>
          <h3>DURATION</h3>
          <p>1 week</p>
        </div>
        <div>
          <h3>YEAR</h3>
          <p>2023</p>
        </div>
      </div>

      <div className="images-amazon">
        <div className="first-img-amazon"></div>
        <div className="second-img-amazon"></div>
      </div>
    </div>
  );
};

export default AmazonPage;
